/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import Vue from "vue/dist/vue.esm";
import Turbolinks from "turbolinks"
import TurbolinksAdapter from 'vue-turbolinks'

Rails.start()
Turbolinks.start()
Vue.use(TurbolinksAdapter)
Vue.config.productionTip = false;

$(document).on('ready turbolinks:load', function () {
  // hide alerts after 5 seconds
  if($(".alert").length) {
    setTimeout(function() { $(".alert").hide(); }, 5000);
  }
})
